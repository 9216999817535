import { mapActions } from 'vuex'
import SContainer from '../../../components/ui/s-container'
import IssueForm from '../../../components/IssueForm'
import sentIcon from '../../../assets/images/issues/issue_sent_icon.svg'

export default {
  name: 'IssuesNew',

  components: {
    SContainer,
    IssueForm,
    SLink: () => import('../../../components/ui/s-link'),
    CardMessage: () => import('../../../components/CardMessage'),
  },

  data() {
    return {
      protocol: null,
      issue: null,
      sentIcon,
    }
  },

  methods: {
    ...mapActions(['createIssue']),

    async sendIssue(issue) {
      await this.createIssue(issue)
      this.protocol = issue.protocol
      this.issue = issue
    },
  },
}
